import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { EventOddsWrapperComponent } from 'src/app/modules/sport/components/event-odds/wrapper/event-odds-wrapper.component';
import { SportsHomePageComponent } from 'src/app/modules/sport/components/home-page/home-page.component';
import { OddModel } from 'src/app/shared/models/coupon.model';

export type EventType = 'prematch' | 'outright' | 'special' | 'goalscorer' | 'odds-boost' | 'player-specials';

export class SportState {
  selectedPrematch: EventSummaryModel[];
  sportsList: SportModel[];
  sportsQuicklinks: SportQuicklinkModel[];
  isQuicklinksLoading: boolean;
  eventSelection: EventSelectionState;
  selectedPlayerIds: number[];
  playersData: PlayerViewModel[];
  isItCorrectScore: boolean;
  areas: AreaModel[];
  regions: RegionModel[];
  ui: SportUIState;
  favouriteSports: number[];
  oddsBoostInfoModalCMSContent: OddsBoostInfoModalContent;
  marketTypeIds: MarketTypeIdsModel[];
  oddsBoostSportData: EventSummaryModel;
  competitionsList: CompetitionsListModel[];
  displayCompetitionList: boolean;
  dayLimit: number;
  competitionBetInsights: any[];
  displayCompetitionBetInsight: boolean;

  constructor(init: Partial<SportState>) {
    Object.assign(this, init);
  }
}

export class EventSelectionState {
  allCompetitionByCountry: SportModel[];
  areaAndRegionCache: { areas: AreaModel[]; regions: RegionModel[]; visible: boolean }[];
  areaMarkets: MarketModel[];
  autoForwardHappened: boolean;
  competitionsAZ: FlattenedSportModel[];
  eventSelectionDepth: number;
  eventSelectionLoaded: boolean;
  isSportsListOpened: boolean;
  playerSpecialsSport: SportModel[];
  outrights: SportModel[];
  restoreAreaRegionsCache: boolean;
  selectedArea: AreaModel;
  selectedAreaId: number;
  selectedMarket: MarketModel;
  selectedQuicklink: SportQuicklinkModel;
  selectedSport: SportModel;
  specialSports: SportModel[];
  topCompetitions: MatchModel[];

  constructor(init: Partial<EventSelectionState>) {
    Object.assign(this, init);
  }
}

export interface PlayerViewModel {
  tournamentId: number;
  data: PlayerViewDataModel[];
}

export type PlayerViewDataModel = [TeamDataForPlayerViewModel, TeamDataForPlayerViewModel, OddModel[]];

export class SportUIState {
  openedBreadcrumbSection: string;

  constructor(init: Partial<SportUIState>) {
    Object.assign(this, init);
  }
}

export class TeamDataForPlayerViewModel {
  extParentTeamID: number;
  data: MatchModel[];

  constructor(init: Partial<TeamDataForPlayerViewModel>) {
    Object.assign(this, init);
  }
}

export enum Team {
  Home,
  Away,
}

export enum MarketType {
  Default = 0,
  Multiline = 1,
}

export enum GroupingType {
  Prematch = 0,
  Outright = 1,
  GoalScorer = 2,
}

export enum ServiceOwner {
  NotSet = 0,
  HomeTeam = 1,
  AwayTeam = 2,
}

export enum Quicklinks {
  Competitions = 'competitions',
  TodaysMatches = 'todaysMatches',
  Live = 'live',
  OddBoost = 'oddBoost',
  Outrights = 'outrights',
  Specials = 'specials',
  Coupons = 'coupons',
  CompetitionList = 'competitionlist',
  GoalScorer = 'goal_scorer',
  PlayerSpecials = 'player_specials',
}
export class MarketTypeIdsModel {
  lookupCode: string;
  marketTypeId: number;

  constructor(init: Partial<MarketTypeIdsModel>) {
    Object.assign(this, init);
  }
}

export class SportModel {
  id: number;
  menuUniqueId: string;
  name: string;
  groupingType: GroupingType = GroupingType.Prematch;
  order: number = -1;
  oddCount: number = 0;
  categories: CategoryModel[] = [];
  url?: string;

  constructor(init: Partial<SportModel>) {
    Object.assign(this, init);
  }
}

export class FlattenedSportModel {
  id: number;
  name: string;
  order: number = -1;
  oddCount: number = 0;
  tournaments: TournamentModel[];

  constructor(init: Partial<FlattenedSportModel>) {
    Object.assign(this, init);
  }
}

export class CategoryModel {
  id: number;
  name: string;
  oddCount: number = 0;
  selectedInView: boolean = false;
  sportId: number;
  menuUniqueId: string;
  menuUniqueName?: string;
  tournaments: TournamentModel[] = [];

  constructor(init: Partial<CategoryModel>) {
    Object.assign(this, init);
  }
}

export class TournamentModel {
  id: number;
  name: string;
  eventCount: number = 0;
  oddCount: number = 0;
  selectedInView: boolean = false;
  regions: RegionModel[] = [];
  matches: MatchModel[] = [];
  initRegionId: number = 0;
  initAreaId: number = 0;
  isBetBuilder: boolean = false;
  category?: CategoryModel;

  constructor(init: Partial<TournamentModel>) {
    Object.assign(this, init);
  }
}

export class RegionModel {
  id: number;
  name: string;
  order: number = -1;
  isDefault: boolean = false; // Set to true if no region data is provided by api, and one is being created for structure purposes
  areas: AreaModel[];
  areaIds: number[];
  belongsTo: number[];

  constructor(init: Partial<RegionModel>) {
    Object.assign(this, init);
  }
}

export class AreaModel {
  id: number;
  name: string;
  order: number = -1;
  isDefault: boolean = false; // Set to true if no area data is provided by api, and one is being created for structure purposes
  groupingType: GroupingType = GroupingType.Prematch; // Duplicated with MarketModel to cater for different api responses
  markets: MarketModel[] = [];
  belongsTo: number[];

  constructor(init: Partial<AreaModel>) {
    Object.assign(this, init);
  }
}

export class MarketModel {
  id: number;
  name: string;
  groupingType: GroupingType = GroupingType.Prematch; // Duplicated with AreaModel to cater for different api responses
  marketType: MarketType = MarketType.Default; // Maps to MultilineType/TemplateType
  spreadValue: number; // Maps to SpecialBetValue
  spreadDisplayValue: string; // Maps to SpecialValueDisplay
  selections: SelectionModel[];
  description: string;
  typeId: number;
  order: number;
  groupedMarket: MarketModel[];

  constructor(init: Partial<MarketModel>) {
    Object.assign(this, init);
  }
}

export class MarketModelSpreadValueGroup extends MarketModel {
  spreadValues: { spreadValue: number; spreadDisplayValue: string }[] = [];
  spreadValue = undefined;
  showSpreadValue = this.selections.some(s => s.showSpreadValue);
}

export class SelectionModel {
  id: number;
  name: string;
  shortcut: string;
  spreadValue: number; // Maps to SpecialValue
  spreadDisplayValue: string; // Maps to SpecialValueDisplay
  showSpreadValue?: boolean = false;
  order: number = -1;
  names?: string[] = [];

  constructor(init: Partial<SelectionModel>) {
    Object.assign(this, init);
  }
}

export class MatchModel {
  id: number;
  date: Date;
  name: string;
  homeTeam: string;
  awayTeam: string;
  smartBetCode: string;
  sportId: number;
  sportName: string;
  categoryId: number;
  eventCategory: string;
  categoryName: string;
  categoryOrder: number;
  tournamentId: number;
  tournamentName: string;
  tournamentOrder: number;
  oddCount: number = 0;
  odds: OddModel[] = [];
  betBuilder: OddModel[] = [];
  groupedMarket: OddModel[];
  goalscorer?: GoalscorerModel;
  correctScoreOdds?: CorrectScoreOddsModel;
  oddsGroupedBySpreadValue?: [OddModel, OddModel][];
  selectedInView: boolean = false;
  isBankerDisabled: boolean;
  combinability: number;
  fixed: boolean;
  allowFixed: boolean;
  isOutright: boolean = false;
  matchTime: number;
  eventStatus: { Id: number; Description: string }; // new Match Status
  matchStatus: number; // Old Match Status for backwards compatibility
  serviceOwner: ServiceOwner;
  score: string;
  setScores: string;
  homeGameScore: number;
  awayGameScore: number;
  groupingType: number;
  hasExpiredEvents: boolean;
  isBetBuilder: boolean;

  constructor(init: Partial<MatchModel>) {
    Object.assign(this, init);
  }

  get matchDay(): string {
    return this.date.toString().substring(0, 10);
  }

  get playerName(): string {
    return this.name.lastIndexOf('[') > -1 ? this.name.substring(this.name.lastIndexOf('[') + 1, this.name.lastIndexOf(']')) : this.name;
  }
}
export class GoalscorerModel {
  hasMorePlayers: boolean;
  hasMarketDescriptions: boolean;
  marketOrder: number;
  availableMarkets: GoalscorerAvailableMarketsModel[];
  teams: GoalscorerTeamsModel[];
}

export class GoalscorerAvailableMarketsModel {
  uniqueKey: string;
  marketTypeId: number;
  marketName: string;
  marketDescription: string;
}

export class GoalscorerTeamsModel {
  teamID: number;
  teamName: string;
  teamOrder: number;
  players: GoalscorerPlayersModel[];
}

export class GoalscorerPlayersModel {
  playerID: number;
  playerName: string;
  markets: OddModel[];
}
export class CorrectScoreOddsModel {
  homeToWin: OddModel[];
  awayToWin: OddModel[];
  draw: OddModel[];
  others: OddModel[];

  constructor(init: Partial<CorrectScoreOddsModel>) {
    Object.assign(this, init);
  }
}

export class EventSummaryModel {
  sportId: number;
  sportName: string;
  area: AreaModel;
  groupingType: number;
  multiLineType: number;
  overUnderType?: number;
  correctScoreType?: number;
  matches: MatchModel[];
  marketSelected: MarketModel;
  groupMatches: MatchModel[][];
  groupMarketSelected: MarketModel[];
  availableSportsList: number[];

  constructor(init: Partial<EventSummaryModel>) {
    Object.assign(this, init);
  }
}

export class RegionAreaDataModel {
  areas: AreaModel[];
  region: RegionModel;

  constructor(init: Partial<RegionAreaDataModel>) {
    Object.assign(this, init);
  }
}

export type RegionAreaDropdownViewModel = [RegionAreaDataModel, RegionAreaDataModel, RegionAreaDataModel][];

export class SportQuicklinkModel {
  type: Quicklinks;
  label: string;
  link: QuicklinkModel;
  isNew: boolean = false;
  disabled: boolean = true;

  constructor(init: Partial<SportQuicklinkModel>) {
    Object.assign(this, init);
  }
}

export class QuicklinkModel {
  title?: string;
  url?: string;

  constructor(init: Partial<QuicklinkModel>) {
    Object.assign(this, init);
  }
}

export interface OddsBoostInfoModalContent {
  oddsBoostInfoModalTitle: string;
  oddsBoostInfoModalSubtitle: string;
  oddsBoostInfoModalBody: string;
  oddsBoostInfoModalCloseCTA: string;
}

export interface SportBreadcrumbDropdownItem {
  label: string;
  url: string;
  disabled?: boolean;
}

export interface SportBreadcrumbRouteData {
  label: (instance: SportsHomePageComponent | EventOddsWrapperComponent) => string;
  id: (instance: SportsHomePageComponent | EventOddsWrapperComponent) => string;
  getChildren: (
    instance: SportsHomePageComponent | EventOddsWrapperComponent,
    route?: ActivatedRoute
  ) => Observable<SportBreadcrumbDropdownItem[]>;
}

export interface SportBreadcrumb {
  id: string;
  label: string;
  url: string;
  children: Observable<SportBreadcrumbDropdownItem[]>;
}

export const eventTypeToQuicklink = {
  'goal-scorer': Quicklinks.GoalScorer, // This most probably is legacy
  'odds-boost': Quicklinks.OddBoost,
  'player-specials': Quicklinks.PlayerSpecials, // Not sure if we need this
  live: Quicklinks.Live,
  outright: Quicklinks.Outrights,
  prematch: Quicklinks.Competitions,
  special: Quicklinks.Specials,
  todaysEvents: Quicklinks.TodaysMatches,
  competitionlist: Quicklinks.CompetitionList,
};

export class CompetitionMatch {
  idEvent: number;
  kmEventId: number;
  eventName: string;
  eventDate: string;

  constructor(init: Partial<CompetitionMatch>) {
    Object.assign(this, init);
  }
}

// TODO... Replace allFootballGoCategories with VSL List from API
export const allFootballGoCategories = [
  {
    id: 15266236,
    name: 'England',
  },
  {
    id: 15266238,
    name: 'Italy',
  },
  {
    id: 15266239,
    name: 'Germany',
  },
  {
    id: 15266240,
    name: 'Spain',
  },
  {
    id: 15266237,
    name: 'France',
  },
  {
    id: 15266353,
    name: 'Holland',
  },
  {
    id: 15266354,
    name: 'Nigeria',
  },
  {
    id: 15266355,
    name: 'Portugal',
  },
  {
    id: 15266356,
    name: 'Saudi Arabia',
  },
  {
    id: 15266480,
    name: 'Africa',
  },
];

export class FixtureInfoModel {
  fixtureId: number;
  matchTime: number;
  matchStatusName: string;
  matchStatusValue: number;
  score: string;
  homeGameScore: number;
  awayGameScore: number;
  periodScores: string;
  coverageTypeName: string;
  coverageTypeValue: number;
  betBuilder: boolean;
  sources: SourceModel[];
  participants: ParticipantModel[];
}

export class SourceModel {
  sourceId: string | number;
  providerId: string;
  providerSource: string;
  offering: string;
}

export class ParticipantModel {
  participantId: string;
  name: string[];
  homeAwayId: string;
  sources: SourceModel[];
}

export class CompetitionsListModel {
  id: string;
  name: string;
  description: string;
  tournamentIds: string;
  isPresetList?: boolean;
}

export enum ProviderType {
  BetGenius = 1,
  BetRadar = 2,
  InnBets = 3,
  FootballGo = 5,
  SportCast = 6,
  StatsPerform = 7,
}
