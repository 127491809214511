import { Injectable, OnDestroy } from '@angular/core';
import { trim, orderBy } from 'lodash-es';
import { forkJoin, Observable, Subject, Subscription, throwError } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap, catchError, map } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { SportQuery } from 'src/app/core/state/sport/sport.query';
import { SportStore } from 'src/app/core/state/sport/sport.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { OddModel } from 'src/app/shared/models/coupon.model';
import {
  AreaModel,
  CorrectScoreOddsModel,
  EventSelectionState,
  EventSummaryModel,
  GoalscorerModel,
  MarketModel,
  MatchModel,
  RegionModel,
  SelectionModel,
} from 'src/app/shared/models/sport.model';
import { GoalscorerService } from 'src/app/modules/sport/services/goalscorer.service';
import { WidgetBetBuilderModel } from 'src/app/shared/models/landing.model';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { EventBetInsightModel } from 'src/app/modules/sport/models/event-view/prematch.model';
import { convertToKMId, IdType } from 'src/app/shared/utils/id-convertor';
import { LanguageService } from 'src/app/core/services/language.service';
import { MatchStore } from 'src/app/modules/sport/state/match-view/match.store';

@Injectable({
  providedIn: 'root',
})
export class SportService implements OnDestroy {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  private readonly regionsCache: any = [];
  private readonly areasCache: any = [];
  private readonly isPlayerAreaSub$: Subscription = undefined;
  private initialPrematchRequestMade: boolean;

  constructor(
    private readonly apiService: APIService,
    private readonly appConfig: AppConfigService,
    private readonly applicationQuery: ApplicationQuery,
    private readonly couponQuery: CouponQuery,
    private readonly couponService: CouponService,
    private readonly goalscorerService: GoalscorerService,
    private readonly sportQuery: SportQuery,
    private readonly sportStore: SportStore,
    private readonly matchStore: MatchStore,
    private readonly languageService: LanguageService
  ) {
    this.isPlayerAreaSub$ = this.sportQuery.isPlayerArea$.subscribe(isPlayerAreaSub => {
      if (!isPlayerAreaSub) {
        this.sportStore.clearPlayersData();
      }
    });

    this.applicationQuery.isSportsSection$
      .pipe(
        distinctUntilChanged(),
        tap(isSportsSection => {
          if (!isSportsSection) {
            // Check reset quicklink is happening and this logic is needed
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  restoreAreaRegionsCacheToggle(bool: boolean): void {
    this.sportStore.updateEventSelection({ restoreAreaRegionsCache: bool });
  }

  restoreAreaRegionsCache(): void {
    this.restoreAreaRegionsCacheToggle(true);
    const newAreas = [];
    const newRegions = [];
    const regionAndAreaCaches = [];
    // Todo: remove the betbuilder condition once bet builder event list implementation is concluded
    this.areasCache
      .filter(area => area.AreaID !== this.appConfig.get('sports').betBuilderId)
      .forEach(areas => {
        areas.forEach(area => {
          const newArea = new AreaModel({
            id: area.AreaID,
            name: area.AreaName,
            order: area.AreaOrder,
          });
          newAreas.push(newArea);
        });

        this.regionsCache
          .filter(region => region.RegionID !== this.appConfig.get('sports').betBuilderRegionId)
          .forEach(regions => {
            regions.forEach(region => {
              const newRegion = new RegionModel({
                areaIds: region.AreaIds,
                id: region.RegionID,
                name: region.RegionName,
                order: region.RegionOrder,
              });
              newRegions.push(newRegion);
            });
          });
        const reducedAreas = newAreas.reduce((acc, x) => acc.concat(acc.find(y => y.name === x.name) ? [] : [x]), []);

        const reducedRegions = newRegions.reduce((acc, x) => acc.concat(acc.find(y => y.name === x.name) ? [] : [x]), []);

        const reducedAreasWithOwnership = reducedAreas.map(area => {
          const belongsTo = [];
          regionAndAreaCaches.forEach((prematch, index) => {
            prematch.areas.forEach(prematchArea => {
              if (area.id === prematchArea.id) {
                belongsTo.push(index);
              }
            });
          });
          return { ...area, belongsTo };
        });

        const reducedRegionsWithOwnership = reducedRegions.map(region => {
          const belongsTo = [];
          regionAndAreaCaches.forEach((prematch, index) => {
            prematch.regions.forEach(prematchRegion => {
              if (region.id === prematchRegion.id) {
                belongsTo.push(index);
              }
            });
          });
          return { ...region, belongsTo };
        });

        this.sportStore.updateAreas(reducedAreasWithOwnership);
        this.sportStore.updateRegions(reducedRegionsWithOwnership);
      });
  }

  getSinglePrematchData(
    language: string = 'en',
    scheduleTimeFrame: number,
    leagueId: string,
    areaId: number = 0,
    regionId: number = 0,
    cacheRegionsAndAreas: boolean = false
  ): Observable<any[]> {
    if (!leagueId) {
      this.handlePrematchData([], cacheRegionsAndAreas);
      return;
    }
    const apiSettings: APISettings = new APISettings({
      contentType: 'application/x-www-form-urlencoded',
      noAuthToken: true,
    });

    const url = `api/feeds/prematch/${language}/${scheduleTimeFrame}/${leagueId}/${areaId}/${regionId}`;
    return this.apiService.get(APIType.SportsbookFeed, url, apiSettings).pipe(
      tap((dataArray: any) => {
        this.handlePrematchData([dataArray], cacheRegionsAndAreas);
      }),
      takeUntil(this.destroy$)
    );
  }

  getPrematchEventData(
    language: string = 'en',
    scheduleTimeFrame: number,
    leagueIds: string[],
    areaId: number = 0,
    regionId: number = 0,
    cacheRegionsAndAreas: boolean = false,
    initialRegionArea: boolean = false,
    isMerged: boolean = false
  ): Observable<any[]> {
    // If we make a request for defaults with areaId 0 and regionId 0, after updating the selected area in the store, don't make an
    // additional request with the changed selected area id
    if (this.initialPrematchRequestMade) {
      this.initialPrematchRequestMade = false;
      if ((areaId === 0 && regionId === 0) || initialRegionArea) {
        return new Observable();
      }
    }
    if ((areaId === 0 && regionId === 0) || initialRegionArea) {
      this.initialPrematchRequestMade = true;
    }
    const apiSettings: APISettings = new APISettings({
      contentType: 'application/x-www-form-urlencoded',
      noAuthToken: true,
    });

    if (isMerged) {
      const tournamentIds = leagueIds.filter(id => id !== undefined).join(',');
      const url = `v1/prematch/competition-list?locale=${language}&scheduleTimeFrame=${scheduleTimeFrame}&tournamentId=${tournamentIds}&areaId=${areaId}&regionId=${regionId}&dayLimit=${this.sportQuery.dayLimit}`;
      return this.apiService.get(APIType.BFFSportsbook, url, apiSettings).pipe(
        tap((dataArray: any) => {
          const mergedPrematchData = this.handleBffPrematchData(dataArray);
          this.handlePrematchData(mergedPrematchData, cacheRegionsAndAreas);
        }),
        takeUntil(this.destroy$)
      );
    }

    const apiCalls = [];

    leagueIds.forEach(leagueId => {
      const url = `api/feeds/prematch/${language}/${scheduleTimeFrame}/${leagueId}/${areaId}/${regionId}`;

      const call = this.apiService.get(APIType.SportsbookFeed, url, apiSettings);
      apiCalls.push(call);
    });

    return forkJoin(apiCalls).pipe(
      tap((dataArray: any) => {
        this.handlePrematchData(dataArray, cacheRegionsAndAreas);
      }),
      takeUntil(this.destroy$)
    );
  }

  getBetInsightConfig() {
    const url = `sportsbook-page?locale=${this.languageService.strapiCMSLocale}&populate[displayCompetitionBetInsight][populate]=*&populate[displayEventBetInsight][populate]=*`;
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });
    return this.apiService.get(APIType.StrapiCms, url, apiSettings).pipe(
      map(res => {
        if (res.data.attributes) {
          this.sportStore.updateDisplayCompetitionBetInsight(res.data.attributes.displayCompetitionBetInsight);
          this.matchStore.updateDisplayEventBetInsight(res.data.attributes.displayEventBetInsight);
        }
      })
    );
  }

  getCompetitionBetInsights(competitionId: string, coverage: number = 1): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    const kmCompetitionId = convertToKMId(parseInt(competitionId, 10), IdType.Competition);

    const url = `v1/prematch/bet-insight/competition?competitionId=${kmCompetitionId}&coverage=${coverage}`;
    return this.apiService.get(APIType.BFFSportsbook, url, apiSettings).pipe(
      tap((data: any) => {
        const parsedInsight = this.handleBetInsightData(data.data);
        this.sportStore.updateCompetitionBetInsights(parsedInsight);
      }),
      takeUntil(this.destroy$)
    );
  }

  handleBetInsightData(data: any): EventBetInsightModel[] {
    data.forEach((insight: EventBetInsightModel) => {
      insight.odds = this.mapBetInsightOddsDataToModel(insight);
    });
    return data;
  }

  mapBetInsightOddsDataToModel = (insight: EventBetInsightModel): OddModel[] => {
    const odds: OddModel[] = [];
    insight.selection.forEach(odd => {
      odds.push(
        new OddModel({
          id: odd.selectionId,
          value: odd.value,
          unboostedValue: odd.unboostedValue,
          sportId: insight?.sportId,
          sportName: insight?.sportName,
          categoryId: insight?.categoryId,
          categoryName: insight?.categoryName,
          tournamentId: insight?.competitionId,
          tournamentName: insight?.competitionName?.trim(),
          matchId: insight?.fixtureId,
          matchName: insight?.fixtureName,
          matchDate: new Date(insight?.fixtureDate),
          marketId: insight.marketId,
          marketTypeId: insight.marketTypeId,
          marketName: insight.marketTypeName,
          smartCode: insight?.smartBetCode,
          eventCategory: 'F', // F = prematch, L = live
          combinability: insight?.combinability,
          selectionId: odd.selectionTypeId,
          selectionTypeId: odd?.selectionId,
          spreadValueDisplay: insight?.lineValue?.toString() || 0,
          spreadValue: insight?.lineValue || 0,
          selectionName: odd.selectionName,
          incompatibleEvents: insight?.incompatibleEvents,
          selected: this.couponService.isOddInCoupon(odd.selectionTypeId),
          enabled: true,
          showSelectionName: true,
        })
      );
    });
    return odds;
  };

  clearInsightData() {
    this.sportStore.updateCompetitionBetInsights(undefined);
  }
  handleBffPrematchData(data: any): any[] {
    const OddsTypeSchema = obj => {
      return {
        OddsTypeID: obj.oddsTypeID,
        OddsTypeName: obj.oddsTypeName,
        OddsDescription: obj.oddsDescription,
        IsOutright: obj.isOutright,
        IsGoalScorer: obj.isGoalScorer,
        IDGroup: obj.iDGroup,
        IDGroupMarketType: obj.iDGroupMarketType,
        TemplateType: obj.templateType,
        OddsTypeOrder: obj.oddsTypeOrder,
        OnlyRegularTime: obj.onlyRegularTime,
        MultilineType: obj.multilineType,
        OddCollectionID: obj.oddCollectionID,
      };
    };

    const MarketSchema = obj => {
      return {
        OddAttribute: OddAttributeSchema(obj.oddAttribute),
      };
    };

    const OddAttributeSchema = obj => {
      return {
        OddTypeID: obj.typeId,
        OddName: obj.name,
        SpecialValueString: obj.specialValueString,
        Order: obj.order,
        SpecialValueDisplay: obj.specialValue,
        SpecialValue: obj.specialValueNumber,
        SelectionDescription: obj.selectionDescription,
      };
    };

    const AreaMarketSchema = obj => {
      return {
        SpecialBetValue: obj.specialValue,
        SpecialCount: obj.specialCount,
        Markets: obj.markets.map(market => MarketSchema(market)),
        OddsType: OddsTypeSchema(obj.oddsType),
        GroupNo: obj.groupNo,
        SpecialValueDisplay: obj.specialValueDisplay,
      };
    };

    const AreaSchema = obj => {
      return {
        AreaID: obj.areaID,
        AreaName: obj.areaName,
        AreaOrder: obj.areaOrder,
        MarketCount: obj.marketCount,
      };
    };

    const RegionSchema = obj => {
      return {
        RegionID: obj.regionID,
        RegionName: obj.regionName,
        RegionOrder: obj.regionOrder,
        AreaIds: obj.areaIds,
      };
    };

    const OutcomeSchema = obj => {
      return {
        OddOutcome: obj.value,
        UnboostedOddValue: obj.unboostedOddValue,
      };
    };

    const MatchOddsSchema = obj => {
      return {
        OddCollectionID: obj.oddCollectionID,
        MatchOddsID: obj.id,
        SmartBetCode: obj.smartCode,
        OddAttribute: {
          OddTypeID: obj.typeId,
          OddName: obj.name,
          SpecialValueString: obj.specialValueString,
          Order: obj.order,
          SpecialValueDisplay: obj.specialValue,
          SpecialValue: obj.specialValueNumber,
          SelectionDescription: obj.selectionDescription,
        },
        Outcome: OutcomeSchema(obj.odd),
      };
    };

    const OddsCollectionSchema = obj => {
      return {
        OddCollectionID: obj.collectionId,
        OddsType: {
          OddsTypeID: obj.typeId,
          OddsTypeName: obj.name,
          OddsDescription: obj.oddsDescription,
          IsOutright: obj.isOutright,
          IsGoalScorer: obj.isGoalScorer,
          IDGroup: obj.iDGroup,
          IDGroupMarketType: obj.iDGroupMarketType,
          TemplateType: obj.templateType,
          OddsTypeOrder: obj.oddsTypeOrder,
          OnlyRegularTime: obj.onlyRegularTime,
          MultilineType: obj.multilineType,
          OddCollectionID: obj.oddCollectionID,
        },
        ScheduleStatus: obj.scheduleStatus,
        SpecialBetValue: obj.specialBetValue,
        SpecialValueDisplay: obj.specialValue,
        Combinability: obj.combinability,
        MatchOdds: obj.selections.map(selection => MatchOddsSchema(selection)),
        GroupNo: obj.groupNo,
      };
    };

    const TeamSchema = obj => {
      return {
        IDTeam: obj.id,
        Name: obj.name,
        ItemOrder: obj.itemOrder,
      };
    };

    const PrematchGoalScorerPlayerSchema = obj => ({
      IDPlayer: obj.iDPlayer,
      PlayerName: obj.playerName,
      Markets: obj.markets.map(market => {
        return {
          OddCollectionID: market.oddCollectionID,
          OddsType: OddsTypeSchema(market.oddsType),
          ScheduleStatus: market.scheduleStatus,
          SpecialBetValue: market.specialBetValue,
          SpecialValueDisplay: market.specialValueDisplay,
          Combinability: market.combinability,
          MatchOdds: market.matchOdds.map(odd => {
            return {
              OddCollectionID: odd.oddCollectionID,
              MatchOddsID: odd.matchOddsID,
              SmartBetCode: odd.smartBetCode,
              OddAttribute: {
                OddTypeID: odd.oddAttribute.oddTypeID,
                OddName: odd.oddAttribute.oddName,
                SpecialValue: odd.oddAttribute.specialValue,
                SpecialValueString: odd.oddAttribute.specialValueString,
                Order: odd.oddAttribute.order,
                SpecialValueDisplay: odd.oddAttribute.specialValueDisplay,
                SelectionDescription: odd.oddAttribute.selectionDescription,
              },
              Outcome: {
                OutcomeID: odd.outcome.outcomeID,
                OddOutcome: odd.outcome.oddOutcome,
                UnboostedOddValue: odd.outcome.unboostedOddValue,
                ScheduleStatus: odd.outcome.scheduleStatus,
              },
              Markup: odd.markup,
              PricePerShare: odd.pricePerShare,
              AvailableShares: odd.availableShares,
            };
          }),
          GroupNo: market.groupNo,
          CompatibleMarkets: market.compatibleMarkets,
        };
      }),
    });

    const ItemSchema = obj => {
      return {
        ItemID: obj.id,
        ItemName: obj.name,
        ItemDate: obj.date,
        EventCategory: obj.eventCategory,
        SmartBetCode: obj.smartBetCode,
        OddsCollection: obj.markets.map(market => OddsCollectionSchema(market)),
        TournamentId: obj.tournamentId,
        TournamentName: obj.tournamentName,
        CategoryId: obj.categoryId,
        CategoryName: obj.categoryName,
        TotalOdds: obj.totalOdds,
        IncompatibleEvents: obj.incompatibleEvents,
        Goalscorer: {
          GoalscorerTeams: obj?.goalscorer?.goalscorerTeams.map(m => {
            return {
              IDTeam: m.iDTeam,
              TeamName: m.teamName,
              TeamOrder: m.teamOrder,
              GoalscorerPlayers: m?.goalscorerPlayerList?.map(t => PrematchGoalScorerPlayerSchema(t)),
            };
          }),
        },
        Teams: obj.teams.map(team => TeamSchema(team)),
      };
    };

    const AreaMatchesSchema = (obj, index) => {
      return {
        SportID: obj.sportId,
        SportName: obj.sportName,
        AreaMarkets: obj.areaMarkets.map(areaMarket => AreaMarketSchema(areaMarket)),
        GroupingType: obj.groupingType,
        Area: AreaSchema(obj.area),
        Region: RegionSchema(obj.region),
        Items: obj.eventList[index].map(t => ItemSchema(t)),
        AreaMarketsType: obj.areaMarketsType,
        MarketCount: obj.marketCount,
      };
    };
    const mergedValue = [];
    for (let index = 0; index < data.data.areaMatches[0].eventList.length; index++) {
      const result = {
        AreaMatches: data.data.areaMatches.map(areaMatch => AreaMatchesSchema(areaMatch, index)),
        Areas: data.data.areas.map(t => AreaSchema(t)),
        Regions: data.data.regions.map(t => RegionSchema(t)),
        TotalNoOfItems: data.data.totalNoOfItems,
      };
      mergedValue.push(result);
    }
    return mergedValue;
  }

  handlePrematchData(dataArray: any, cacheRegionsAndAreas: boolean) {
    if (dataArray?.length === 0) {
      return;
    }
    const newAreas = [];
    const newRegions = [];
    const parsed = [];
    const regionAndAreaCaches = [];

    dataArray.forEach((data, index) => {
      if (data.AreaMatches.length === 0) {
        return;
      }
      const cache = { areas: [], regions: [], visible: true };
      if (data.Areas.length) {
        // Todo: remove the betbuilder condition once bet builder event list implementation is concluded
        this.areasCache[index] = data.Areas.filter(area => area.AreaID !== this.appConfig.get('sports').betBuilderId);

        this.areasCache[index].forEach(area => {
          const newArea = new AreaModel({
            id: area.AreaID,
            name: area.AreaName,
            order: area.AreaOrder,
          });
          newAreas.push(newArea);
          cache.areas.push(newArea);
        });

        if (data.Regions.length) {
          this.regionsCache[index] = data.Regions.filter(region => region.RegionID !== this.appConfig.get('sports').betBuilderRegionId);

          this.regionsCache[index].forEach(region => {
            const newRegion = new RegionModel({
              areaIds: region.AreaIds,
              id: region.RegionID,
              name: region.RegionName,
              order: region.RegionOrder,
            });
            newRegions.push(newRegion);
            cache.regions.push(newRegion);
          });
          regionAndAreaCaches.push(cache);
        }
      }

      if (data.AreaMatches[0]) {
        parsed.push(this.mapMatchSummaryDataToModel(data.AreaMatches[0]));
      } else {
        parsed.push({ ...this.sportQuery.selectedPrematch[index] });
      }
      if (!parsed) {
        return;
      }

      if (parsed[index] && parsed[index].area) {
        const correctScoreAreaIds = this.appConfig.get('correctScoreAreaIds');
        correctScoreAreaIds.forEach((id: number) => {
          if (parsed[index].area.id === id) {
            this.sportStore.updateIsItCorrectScore(true);
          } else {
            this.sportStore.updateIsItCorrectScore(false);
          }
        });
      }
    });

    if (cacheRegionsAndAreas) {
      this.sportStore.updateEventSelection({ areaAndRegionCache: regionAndAreaCaches });
    }

    const reducedAreas = newAreas.reduce((acc, x) => acc.concat(acc.find(y => y.name === x.name) ? [] : [x]), []);

    const reducedRegions = newRegions.reduce((acc, x) => acc.concat(acc.find(y => y.name === x.name) ? [] : [x]), []);

    const reducedAreasWithOwnership = reducedAreas.map(area => {
      const belongsTo = [];
      regionAndAreaCaches.forEach((prematch, index) => {
        prematch.areas.forEach(prematchArea => {
          if (area.id === prematchArea.id) {
            belongsTo.push(index);
          }
        });
      });
      return { ...area, belongsTo };
    });

    const reducedRegionsWithOwnership = reducedRegions.map(region => {
      const belongsTo = [];
      regionAndAreaCaches.forEach((prematch, index) => {
        prematch.regions.forEach(prematchRegion => {
          if (region.id === prematchRegion.id) {
            belongsTo.push(index);
          }
        });
      });
      return { ...region, belongsTo };
    });

    this.sportStore.updateAreas(reducedAreasWithOwnership);
    this.sportStore.updateRegions(reducedRegionsWithOwnership);

    this.sportStore.updateSelectedPrematch(parsed);

    if (this.sportQuery.selectedArea) {
      parsed.forEach(data => {
        if (data.area.id === this.sportQuery.selectedArea.id) {
          this.sportStore.updateEventSelection({
            selectedMarket: data.area.markets[0],
            areaMarkets: data.area.markets,
            selectedAreaId: data.area.id,
          });
        }
      });
    } else {
      if (parsed[0]) {
        this.sportStore.updateEventSelection({
          selectedMarket: parsed[0].area.markets[0],
          areaMarkets: parsed[0].area.markets,
          selectedAreaId: parsed[0].area.id,
        });
      }
    }
  }

  mapMatchSummaryDataToModel(
    responseData: any,
    sort: boolean = true,
    groupMarket: boolean = false,
    availableSportsList: number[] = [1]
  ): EventSummaryModel {
    if (!responseData) {
      return;
    }
    const marketData = responseData.AreaMarkets[0];
    const isGoalscorer = responseData.GroupingType === 2;

    // order matches by datetime as default order
    const matches: MatchModel[] = sort
      ? orderBy(
          responseData.Items.map(item =>
            this.mapMatchDataToModel(item, responseData.SportID, responseData.SportName, isGoalscorer && !!item.Goalscorer)
          ),
          'date'
        )
      : responseData.Items.map(item =>
          this.mapMatchDataToModel(item, responseData.SportID, responseData.SportName, isGoalscorer && !!item.Goalscorer)
        );

    const mappedMarket: MarketModel[] = [];
    responseData.AreaMarkets.forEach(market => {
      const selections: SelectionModel[] = [];

      market.Markets.forEach(selection => selections.push(this.mapSelectionDataToModel(selection)));

      mappedMarket.push(
        new MarketModel({
          id: market.OddsType.OddsTypeID,
          typeId: market.OddsType.IDGroupMarketType,
          name: market.OddsType.OddsTypeName,
          description: market.OddsType.OddsDescription,
          spreadDisplayValue: market.SpecialValueDisplay,
          spreadValue: market.SpecialBetValue,
          selections,
        })
      );
    });

    const correctScoreType = this.appConfig.get('correctScoreAreaIds').filter(data => data === responseData.Area.AreaID).length ? 1 : 0;

    const overUnderType = parseFloat(marketData.SpecialValueDisplay) !== 0 ? 1 : 0;
    const markets: MarketModel[] = [];
    const groupedMarkets: MarketModel[] = [];
    const totalGoalsMarketId = this.appConfig.get('sports').totalGoalsMarketId;
    const groupedMarketCount = mappedMarket.filter(marketValue => marketValue.id === totalGoalsMarketId).length;
    if (groupedMarketCount > 1) {
      mappedMarket.map(marketValue => {
        if (groupMarket && marketValue.id === totalGoalsMarketId && responseData.SportID === 1) {
          marketValue.name = marketValue.name.replace('Total Goals', 'Over/Under');
          groupedMarkets.push(marketValue);
          if (!markets.find(t => t.id === totalGoalsMarketId) && marketValue.spreadValue === 2.5) {
            markets.push(
              new MarketModel({
                id: marketValue.id,
                typeId: marketValue.typeId,
                name: $localize`Total Goals`,
                description: marketValue.description,
                spreadDisplayValue: marketValue.spreadDisplayValue,
                spreadValue: marketValue.spreadValue,
                selections: marketValue.selections,
              })
            );
          }
        } else {
          markets.push(marketValue);
        }
      });
    } else {
      markets.push(...mappedMarket);
    }
    // Set grouped market for football only
    if (groupMarket && responseData.SportID === 1 && markets.find(t => t.name === 'Total Goals')) {
      markets.find(t => t.name === 'Total Goals').groupedMarket = groupedMarkets;
    }
    return new EventSummaryModel({
      sportId: responseData.SportID,
      sportName: responseData.SportName,
      groupingType: responseData.GroupingType,
      multiLineType: marketData.Markets.length > 3 ? 1 : 0,
      correctScoreType,
      overUnderType,
      area: new AreaModel({
        id: responseData.Area.AreaID,
        name: responseData.Area.AreaName,
        order: responseData.Area.AreaOrder,
        isDefault: true,
        markets,
      }),
      marketSelected: markets[0],
      matches,
      availableSportsList,
    });
  }

  mapLiveMatchSummaryDataToModel(responseData: any): EventSummaryModel {
    if (!responseData?.Events?.length) {
      return;
    }
    const matches: MatchModel[] = [];
    responseData.Events.forEach(item => matches.push(this.mapLiveMatchDataToModel(item)));
    const markets = [];
    const selections: SelectionModel[] = [];
    const matchOdds = matches[0].odds;

    matchOdds.forEach(odd =>
      selections.push(
        new SelectionModel({
          id: odd.selectionId,
          name: odd.selectionName,
        })
      )
    );

    markets.push(
      new MarketModel({
        id: matchOdds[0]?.marketTypeId,
        typeId: matchOdds[0]?.marketTypeId,
        name: matchOdds[0]?.marketName,
        description: '',
        selections,
      })
    );

    return new EventSummaryModel({
      sportId: responseData.Events[0].SportId,
      area: new AreaModel({
        id: 1,
        name: 'Main',
        order: 0,
        isDefault: true,
        markets,
      }),
      matches,
    });
  }

  mapBoostedOddsDataToModel = (responseData: any, marketTypeId: number): WidgetBetBuilderModel[] => {
    if (!responseData?.length) {
      return;
    }
    const data: WidgetBetBuilderModel[] = [];
    responseData.forEach(boosted => {
      data.push({
        noOfSelections: boosted.NoOfSelections,
        odd: new OddModel({
          id: boosted.IDSelection,
          value: boosted.OddValue,
          unboostedValue: boosted.UnboostedOddValue,
          sportId: boosted.IDSport,
          sportName: boosted.SportName,
          categoryId: boosted.IDCategory,
          categoryName: boosted.CategoryName,
          tournamentId: boosted.IDTournament,
          tournamentName: boosted.TournamentName?.trim(),
          matchId: boosted.IDEvent,
          matchName: boosted.EventName,
          matchDate: boosted.EventDate,
          marketId: boosted.IDMarket,
          marketTypeId: marketTypeId,
          marketName: boosted.MarketTypeName,
          smartCode: parseInt(boosted.SmartBetCode, 10),
          eventCategory: boosted.EventCategory,
          combinability: boosted.Combinability,
          selectionId: boosted.IDSelection,
          selectionTypeId: boosted.IDSelectionType,
          selectionName: boosted.SelectionName,
          selectionNames: boosted.SelectionName?.split(/[,&]/).map(name => name.trim()),
          incompatibleEvents: boosted.IncompatibleEvents,
          selected: this.couponService.isOddInCoupon(boosted.IDSelection),
          enabled: true,
        }),
      });
    });
    return data;
  };

  mapSelectionDataToModel(responseData: any): SelectionModel {
    return new SelectionModel({
      id: responseData.OddAttribute.OddTypeID,
      name: responseData.OddAttribute.OddName,
      spreadValue: responseData.OddAttribute.SpecialValue,
      spreadDisplayValue: responseData.OddAttribute.SpecialValueDisplay,
      order: responseData.OddAttribute.Order,
    });
  }

  mapMatchDataToModel(
    responseData: any, // responseData: 1 of AreaMatches.Items
    sportId?: number,
    sportName?: string,
    isGoalscorer?: boolean
  ): MatchModel {
    const odds: OddModel[] = [];
    let goalscorer: GoalscorerModel;

    const teamHome = responseData?.Teams?.find(t => t.ItemOrder === 1) || {};
    const teamAway = responseData?.Teams?.find(t => t.ItemOrder === 2) || {};

    if (!teamHome?.Name || !teamAway?.Name) {
      const teams = responseData.ItemName.split(' - ');

      teamHome.Name = teamHome?.Name ?? teams[0];
      teamAway.Name = teamAway?.Name ?? teams.length > 1 ? teams[1] : undefined;
    }

    const newMatchModel = new MatchModel({
      id: responseData.ItemID,
      date: responseData.ItemDate,
      name: responseData.ItemName,
      homeTeam: teamHome.Name,
      awayTeam: teamAway.Name,
      smartBetCode: responseData.SmartBetCode,
      oddCount: responseData.TotalOdds,
      categoryId: responseData.CategoryId,
      categoryName: responseData.CategoryName,
      tournamentId: responseData.TournamentId,
      tournamentName: responseData.TournamentName,
      sportId: sportId,
      selectedInView: true,
      eventCategory: responseData.EventCategory,
      matchTime: responseData.MatchTime,
      matchStatus: responseData.MatchStatus,
      serviceOwner: responseData.ServiceOwner,
      score: responseData.Score,
      setScores: responseData.SetScores,
      homeGameScore: responseData.HomeGameScore,
      awayGameScore: responseData.AwayGameScore,
    });

    if (isGoalscorer) {
      goalscorer = this.goalscorerService.mapGoalscorerDataItemsToModel(responseData, sportId, sportName);
      newMatchModel.goalscorer = goalscorer;
    } else {
      responseData.OddsCollection.forEach(oc => {
        odds.push(...this.mapOddDataItemsToModel(responseData, oc, sportId, sportName));
      });

      newMatchModel.odds = odds;
    }

    newMatchModel.groupedMarket = newMatchModel.odds.filter(odd => odd?.marketTypeId === this.appConfig.get('sports').totalGoalsMarketId);

    return newMatchModel;
  }

  mapLiveMatchDataToModel(itemData: any): MatchModel {
    const odds: OddModel[] = [];

    itemData.Markets.forEach(market => {
      odds.push(...this.mapLiveOddDataItemsToModel(itemData, market));
    });

    const teamHome = itemData.Teams.find(t => t.ItemOrder === 1);
    const teamAway = itemData.Teams.find(t => t.ItemOrder === 2);

    const newMatchModel = new MatchModel({
      id: itemData.Id,
      date: itemData.Date,
      name: itemData.Name,
      homeTeam: teamHome.Name,
      awayTeam: teamAway.Name,
      categoryId: itemData.CategoryId,
      categoryName: itemData.CategoryName,
      tournamentId: itemData.TournamentId,
      tournamentName: itemData.TournamentName,
      matchTime: itemData.MatchTime,
      eventStatus: itemData.EventStatus,
      matchStatus: itemData.MatchStatus,
      score: itemData.Score,
      oddCount: itemData.SelectionCount,
      sportId: itemData.SportId,
      odds,
    });

    return newMatchModel;
  }

  mapOddDataItemsToModel = (
    responseDataItem: any, // responseDataItem: 1 of AreaMatches.Items
    responseDataOddsCollection: any, // responseDataOC: 1 of AreaMatches.Items.OddsCollection
    sportId?: number,
    sportName?: string,
    groupingType?: number
  ): OddModel[] =>
    responseDataOddsCollection.MatchOdds.map(
      matchOdd =>
        new OddModel({
          id: matchOdd.MatchOddsID,
          value: matchOdd.Outcome ? matchOdd.Outcome.OddOutcome : undefined,
          unboostedValue: matchOdd.Outcome ? matchOdd.Outcome.UnboostedOddValue : undefined,
          spreadValueDisplay: responseDataOddsCollection.OddsType.IsGoalScorer
            ? responseDataOddsCollection.OddsType.OddsTypeName
            : matchOdd.OddAttribute.SpecialValueDisplay === '0'
            ? responseDataOddsCollection.SpecialBetValue
            : matchOdd.OddAttribute.SpecialValueDisplay,
          spreadValue: responseDataOddsCollection.OddsType.IsGoalScorer
            ? responseDataOddsCollection.OddsType.OddsTypeName
            : responseDataOddsCollection.SpecialBetValue,
          sportId,
          sportName,
          categoryId: responseDataItem.CategoryId,
          categoryName: responseDataItem.CategoryName,
          tournamentId: responseDataItem.TournamentId,
          tournamentName: responseDataItem.TournamentName,
          matchId: responseDataItem.ItemID,
          matchName: responseDataItem.ItemName,
          matchDate: responseDataItem.ItemDate,
          marketId: responseDataOddsCollection.OddCollectionID,
          marketTypeId: responseDataOddsCollection.OddsType.IDGroupMarketType,
          marketName: responseDataOddsCollection.OddsType.OddsTypeName,
          smartCode: responseDataItem.SmartBetCode,
          eventCategory: responseDataItem.EventCategory,
          combinability: responseDataOddsCollection.Combinability,
          selectionId: matchOdd.OddAttribute.OddTypeID,
          selectionName: matchOdd.OddAttribute.OddName,
          isBetBuilder: responseDataOddsCollection.OddsType.OddsTypeID === this.couponQuery.preCannedBetBuilderMarketTypeID,
          incompatibleEvents: responseDataItem.IncompatibleEvents,
          selected: this.couponService.isOddInCoupon(matchOdd.MatchOddsID),
          enabled: responseDataItem.EventCategory === 'L' ? (matchOdd.Outcome ? Boolean(matchOdd.Outcome.ScheduleStatus) : false) : true,
          groupingType,
        })
    );

  mapLiveOddDataItemsToModel(itemData: any, market: any): OddModel[] {
    const matchOdds: OddModel[] = [];

    market.Selections.forEach(selection => {
      matchOdds.push(
        new OddModel({
          id: selection.Id,
          value: selection.Odds ? selection.Odds[0].Value : undefined,
          spreadValue: 0,
          sportId: itemData.SportId,
          sportName: itemData.SportName,
          categoryId: itemData.CategoryId,
          categoryName: itemData.CategoryName,
          tournamentId: itemData.TournamentId,
          tournamentName: itemData.TournamentName,
          incompatibleEvents: itemData.IncompatibleEvents,
          combinability: market.Combinability,
          matchId: itemData.Id,
          matchName: itemData.Name,
          matchDate: itemData.Date,
          smartCode: -1,
          marketId: market.Id,
          marketTypeId: market.TypeId,
          marketName: market.MarketNameRaw,
          selectionId: selection.TypeId,
          selectionName: selection.Name,
          selected: this.couponService.isOddInCoupon(selection.Id),
          enabled: selection.Odds ? Boolean(selection.Odds[0].Status) : false,
          eventCategory: 'L',
        })
      );
    });

    return matchOdds;
  }

  parseOddsForCorrectScore(odds: OddModel[]): CorrectScoreOddsModel {
    const homeToWin = [];
    const awayToWin = [];
    const draw = [];
    const others = [];
    odds.forEach((odd: OddModel) => {
      if (odd.selectionName === 'Others') {
        others.push(odd);
      } else {
        if (odd.selectionName.length < 4) {
          const selectionName = odd.selectionName.split(':');

          if (selectionName[0] > selectionName[1]) {
            homeToWin.push(odd);
          } else if (selectionName[0] < selectionName[1]) {
            awayToWin.push(odd);
          } else if (selectionName[0] === selectionName[1]) {
            draw.push(odd);
          }
        } else {
          const selections = odd.selectionName.split('/');
          const selectionName = selections[selections.length - 1].split(':');

          if (selectionName[0] > selectionName[1]) {
            homeToWin.push(odd);
          } else if (selectionName[0] < selectionName[1]) {
            awayToWin.push(odd);
          } else if (selectionName[0] === selectionName[1]) {
            draw.push(odd);
          } else if (selectionName[0] === 'Other') {
            others.push(odd);
          }
        }
      }
    });

    const homeLength = homeToWin.length;
    const awayLength = awayToWin.length;
    let difference;
    if (homeLength > awayLength) {
      difference = homeLength - awayLength;
      for (let i = 0; i < difference; i++) {
        awayToWin.push({});
      }
      const differenceForDraw = homeLength - draw.length - 1; // -1 for the 'Other' odd
      for (let i = 0; i < differenceForDraw; i++) {
        draw.push({});
      }
    } else {
      difference = awayLength - homeLength;
      for (let i = 0; i < difference; i++) {
        homeToWin.push({});
      }
      const differenceForDraw = homeLength - draw.length - 1; // -1 for the 'Other' odd
      for (let i = 0; i < differenceForDraw; i++) {
        draw.push({});
      }
    }

    const newCorrectScoreModel = new CorrectScoreOddsModel({
      homeToWin,
      awayToWin,
      draw,
      others,
    });

    return newCorrectScoreModel;
  }

  groupBySpreadValue(match: MatchModel): [OddModel, OddModel][] {
    const key = 'spreadValueDisplay';
    const oddsGroupedBySpreadValue = [];
    const flags = [];
    const uniqueSpreadValues = [];

    for (const odd of match.odds) {
      if (flags[odd[key]]) {
        continue;
      }
      flags[odd[key]] = true;
      uniqueSpreadValues.push(odd[key]);
    }

    uniqueSpreadValues.forEach(spreadValueDisplay => {
      const spreadGroup = [];
      match.odds.forEach(odd => {
        if (
          odd[key] === spreadValueDisplay &&
          odd?.marketTypeId === this.sportQuery.selectedMarket.typeId &&
          odd?.spreadValue === this.sportQuery.selectedMarket.spreadValue
        ) {
          spreadGroup.push(odd);
        }
      });
      if (spreadGroup.length > 0) {
        oddsGroupedBySpreadValue.push(spreadGroup);
      }
    });

    return oddsGroupedBySpreadValue;
  }

  updateEventSelection(eventSelection: Partial<EventSelectionState>): void {
    this.sportStore.updateEventSelection(eventSelection);
  }

  updateSelectedPrematch(selectedPrematch: EventSummaryModel[]): void {
    this.sportStore.updateSelectedPrematch(selectedPrematch);
  }

  getMostPopularEvents(sportId: number, language: string = 'en'): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    const { leagueCount, noOfEvents } = this.appConfig.get('sports').topCompetitions;
    const URL = `api/feeds/prematch/mostpopularsports/${language}/${sportId}/${leagueCount}/${noOfEvents}`;
    return this.apiService.get(APIType.SportsbookFeed, URL, apiSettings).pipe(
      tap((responseData: any) => {
        if (!responseData || !responseData.length) {
          this.sportStore.updateEventSelection({ topCompetitions: [] });
          return;
        }
        const topCompetitions = this.mapMatchSummaryDataToModel(responseData[0].AreaMatches[0]);

        const tournaments = topCompetitions.matches.reduce((acc, x) => {
          x.name = `${x.categoryName} - ${x.tournamentName}`;
          return acc.concat(acc.find(y => trim(y.tournamentName) === trim(x.tournamentName)) ? [] : [x]);
        }, []);

        this.sportQuery.competitionsList.forEach(competition => {
          if (competition.isPresetList) {
            if (tournaments.length > 0) {
              const competitions = this.sportQuery.competitionsList;
              if (!competitions.find(c => c.name === competition.name).tournamentIds) {
                const tournamentId = tournaments.map(t => t.tournamentId).join(',');
                competitions.find(c => c.name === competition.name).tournamentIds = tournamentId;
                this.sportStore.updateCompetitionsList(competitions, this.sportQuery.displayCompetitionList, this.sportQuery.dayLimit);
              }
            }
          }
        });

        this.sportStore.updateEventSelection({ topCompetitions: tournaments });
      })
    );
  }

  fetchFootballGoResults(season, channelId): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    return this.apiService.get(APIType.SportsbookVslFeed, `api/v1/gameweekresults/${season}/${channelId}`, apiSettings).pipe(
      tap((responseData: any) => {
        return responseData;
      }),
      catchError(error => {
        return throwError(error);
      }),
      takeUntil(this.destroy$)
    );
  }

  fetchFootballGoTable(season: string): Observable<any> {
    return this.apiService.get(APIType.SportsbookVslFeed, `api/v1/leaguetables/${season}`).pipe(
      tap((response: any) => {
        const tableData = response.forEach(data => {
          return (data.teams = Object.values(data.teams));
        });
        return tableData;
      }),
      catchError(error => {
        return throwError(error);
      }),
      takeUntil(this.destroy$)
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();

    this.isPlayerAreaSub$.unsubscribe();
  }
}
